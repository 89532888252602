import React, { useEffect, useRef } from "react";
import logo from "./logo.svg";
import "./App.css";
import ToasterProvider from "./providers/ToasterProvider";
import LoaderProvider from "./providers/LoaderProvider";
import RootRouter from "./RootRouter";
import Layout from "./components/Layout";
import { ParallaxProvider } from "react-scroll-parallax";
import AOS from "aos";
import "aos/dist/aos.css";
import { useDispatch, useSelector } from "react-redux";
import {
  AwardSectionNotScrolledAction,
  AwardSectionScrolledAction,
  CultureNotScrolledAction,
  CultureScrolledAction,
  navNotScrolledAction,
  navScrolledAction,
  setCookieModal,
} from "./stores/actions/globalAction";
import { StoreState } from "./models/reduxModels";

function App() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  let prevScrollTop = document.documentElement.scrollTop;
  const dispatch = useDispatch();

  const HandleOnScroll = () => {
    let currScrollTop = document.documentElement.scrollTop;
    const pageWidth = document.documentElement.clientWidth;
    
    let awardContainerOffsetHeight =
      document.getElementById("awardContainer")?.offsetHeight;

 
    let cultureImageOffsetHeight =
      document.getElementById("culturalImage")?.offsetHeight;

    if (
      cultureImageOffsetHeight &&
      pageWidth >= 1100 &&
      currScrollTop + 10 >= cultureImageOffsetHeight
    ) {
      dispatch(CultureScrolledAction() as any);
    } else {
      dispatch(CultureNotScrolledAction() as any);
    }
    if (
      !!awardContainerOffsetHeight &&
      pageWidth >= 1100 &&
      currScrollTop >= 2690 &&
      currScrollTop <= 4320
    ) {
     
      dispatch(AwardSectionScrolledAction() as any);
    } else if (
      !!awardContainerOffsetHeight &&
      pageWidth <= 700 &&
      currScrollTop >= 2200 &&
      currScrollTop <= 3850
    ) {
      dispatch(AwardSectionScrolledAction() as any);
    } else if (
      !!awardContainerOffsetHeight &&
      pageWidth >= 700 &&
      pageWidth <= 1100 &&
      currScrollTop >= 5500 &&
      currScrollTop <= 6480
    ) {
      dispatch(AwardSectionScrolledAction() as any);
    } else {
      
      dispatch(AwardSectionNotScrolledAction() as any);
    }

    if (prevScrollTop > currScrollTop) {
      dispatch(navScrolledAction() as any);
    } else {
      dispatch(navNotScrolledAction() as any);
    }
    prevScrollTop = currScrollTop;
  };
  const pageRef = useRef<any>();
  useEffect(() => {
    window.addEventListener("scroll", HandleOnScroll);
    return () => window.removeEventListener("scroll", HandleOnScroll);
  }, []);

  useEffect(() => {
    if (localStorage.getItem("cookieEnabled") !== "yes") {
      setTimeout(() => {
        dispatch(setCookieModal() as any);
      }, 5000);
    }
  }, []);
  const ScrollToTopPos = () => {
    if (pageRef?.current) {
      
      document.documentElement.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }
  };
  const mobileMenuOpen = useSelector(
    (state: StoreState) => state.globals.mobileMenuOpen
  );
 

  const scrollIntoTop = () => {
    if (pageRef?.current) {
      pageRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const contactUsRef = useRef<any>();

  return (
    <div
      className={`App  bg-primaryBgColor   overflow-y-scroll  overflow-x-hidden  lg:h-auto `}
      ref={pageRef}
    >
      <ToasterProvider />
      <LoaderProvider />
      <Layout
        ScrollToTopPos={ScrollToTopPos}
        scrollIntoTop={scrollIntoTop}
        contactUsRef={contactUsRef}
      >
        <RootRouter contactUsRef={contactUsRef} />
      </Layout>
    </div>
  );
}

export default App;
